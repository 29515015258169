import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f675b09d4268abd2a2ab9c05ab64d06c@o4507463324336128.ingest.us.sentry.io/4507463326695424",
  debug: false,
  environment: "production",
  release: "astro-4.15.11c",
  tracesSampleRate: 0,
  sampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});